import React, { useRef, useState } from 'react';
import {
  FunctionField,
  ReferenceField,
  TextField,
  useDataProvider,
  useNotify,
  usePermissions,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import CustomList from '../../Components/List/CustomList';
import PriceDateField from '../../Components/Field/PriceDateField';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { calculateDate, copyText } from '../../utils/helpers';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '../../Components/Card/Card';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import HeaderList from '../../Components/HeaderList/HeaderList';
import { Indicator } from '../../Components/Indicator/Indicator';
import { TokenFieldList } from '../../Components/Field/TokenField';
import { useCurrencies } from '../../utils/helpers/useCurrencies';
import LinkIcon from '@mui/icons-material/Link';

const url =
  process.env.REACT_APP_DEVELOPMENT === 'true'
    ? 'https://develop.pagar.simplefi.tech'
    : 'https://pagar.simplefi.tech';

const ListActivity = () => {
  const { permissions } = usePermissions();
  const merchantSlug = localStorage.getItem('merchantSlug');
  const { currencies } = useCurrencies();

  const copyCheckoutUrl = (e, link) => {
    e.stopPropagation();
    copyText(link);
  };

  return (
    <CustomList
      rowClick={'show'}
      styleDatagrid={{
        '& .column-order_id': {
          width: permissions === 'superadmin' ? '5%' : '5%',
        },
        '& .column-status': {
          textAlign: 'center',
          width: permissions === 'superadmin' ? '10%' : '15%',
        },
        '& .column-price_date': {
          textAlign: 'center',
          width: permissions === 'superadmin' ? '15%' : '20%',
        },
        '& .column-reference': {
          textAlign: 'center',
          width: '15%',
        },
        '& .column-merchant': {
          textAlign: 'center',
          width: '20%',
        },
        '& .column-transactions': {
          textAlign: 'center',
          maxWidth: '20%',
        },
        '& .column-date_created': {
          textAlign: 'center',
          width: permissions === 'superadmin' ? '15%' : '20%',
        },
        '& .column-checkout_url': {
          width: permissions === 'superadmin' ? '5%' : '0%',
        },
        '& .column-options': {
          textAlign: 'right',
          width: '5%',
        },
      }}
      paramsList={{
        perPage: 50,
        sort: { field: 'date_created', order: 'DESC' },
        empty: <Box></Box>,
        actions: (
          <HeaderList
            hasSearch
            coins
            dates
            // hasExport
            status
            comercios={permissions === 'superadmin'}

            hasCreate={permissions !== 'superadmin'}
          // csv={true}
          />
        ),
      }}
      liveUpdates
      playSound
    >
      <TextField source='order_id' label={'ID'} />
      <FunctionField
        label={'Estado'}
        source='status'
        render={(record) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Indicator data={record?.status} />
          </div>
        )}
      />
      <PriceDateField source={'price_date'} label={'Monto'} />
      <FunctionField
        source='reference'
        label={'Referencia'}
        render={(record) => <OrderFieldList record={record} />}
      />
      {permissions === 'superadmin' && (
        <FunctionField
          source='merchant'
          label={'Comercio'}
          render={(record) => <MerchantFieldList record={record} />}
        />
      )}
      <FunctionField
        source='transactions'
        label={'Coin'}
        render={(record) => (
          <TokenFieldList record={record} currencies={currencies} />
        )}
      />
      <FunctionField
        source='date_created'
        label='Fecha'
        render={(record) => {
          const date = calculateDate(record?.date_created).format(
            'DD/MM/YYYY  HH:mm'
          );
          return <Typography>{date}</Typography>;
        }}
      />
      <FunctionField
        source='checkout_url'
        label=''
        render={(record) => {
          if (record?.status === 'pending') {
            return (
              <Tooltip title='Copiar enlace de pago'>
                <IconButton
                  onClick={(e) => copyCheckoutUrl(e, record?.checkout_url)}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            );
          }
        }}
      />
      {merchantSlug ? (
        <OptionsField
          source={'options'}
          label={''}
          merchantSlug={merchantSlug}
        />
      ) : null}
    </CustomList>
  );
};

const OptionsField = ({ merchantSlug, ...rest }) => {
  const [update, { isLoading, isSuccess }] = useUpdate();
  const [open, setOpen] = useState(false);
  const record = useRecordContext(rest);
  const notify = useNotify();
  const link =
    merchantSlug && `${url}/${merchantSlug}/?payment_id=${record.id}`;
  const btRef = useRef(null);
  const isPending = record?.status === 'pending';

  const handleModal = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const closeModal = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const handleClickCopy = (e) => {
    e.stopPropagation();
    if (link) {
      copyText(link);
      notify('Link copiado al portapapeles', { type: 'success' });
    }
  };
  const handleClickOpenCheckout = (e) => {
    e.stopPropagation();
    if (link) {
      window.open(link, '_blank');
    }
  };
  const handleCancel = (e, method, params = null) => {
    e.stopPropagation();
    update('payment_requests', { id: `${record.id}/${method}/?${params}` });
  };

  return (
    <Box sx={{ position: 'relative' }} ref={btRef}>
      <IconButton onClick={handleModal}>
        <MoreVertIcon sx={{ fontSize: '18px' }} />
      </IconButton>
      <Backdrop
        open={open}
        onClick={handleModal}
        sx={{ backgroundColor: 'transparent' }}
      />
      <Popover
        open={open}
        anchorEl={btRef.current}
        onClose={closeModal}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Card
          sx={{ padding: '5px' }}
          sxTitle={{
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 600,
            color: '#000000',
          }}
          sxHeader={{
            backgroundColor: '#FDFDFD',
            border: '1px solid #E7E7E7',
            padding: '0px',
            width: '100%',
            height: '100%',
            margin: '0px',
          }}
        >
          {isPending && (
            <OptionsSelect
              title={'Ir al checkout'}
              icon={
                <OpenInNewIcon
                  sx={{ fontSize: '15px', color: 'rgba(0,0,0,.6)' }}
                />
              }
              onClick={handleClickOpenCheckout}
            />
          )}
          <OptionsSelect
            title={'Copiar link'}
            icon={
              <CopyAllIcon sx={{ fontSize: '15px', color: 'rgba(0,0,0,.6)' }} />
            }
            onClick={handleClickCopy}
          />
          {isPending && (
            <OptionsSelect
              title={'Cancelar orden'}
              icon={
                <DoDisturbIcon
                  sx={{ fontSize: '15px', color: 'rgba(0,0,0,.6)' }}
                />
              }
              onClick={(e) => handleCancel(e, 'cancel')}
            />
          )}
        </Card>
      </Popover>
    </Box>
  );
};

const OptionsSelect = ({ title, onClick, icon }) => {
  return (
    <Box
      sx={{
        padding: '9px 12px',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.1)',
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography sx={{ fontSize: '14px', color: 'rgba(0,0,0,.85)' }}>
        {title}
      </Typography>
    </Box>
  );
};

const OrderFieldList = ({ record }) => {
  return (
    <Typography >{record.reference.data || record.reference._email}</Typography>
  );
};

const MerchantFieldList = () => {
  return (
    <ReferenceField source='merchant_id' reference='merchants' link={false}>
      <TextField
        source='name'
        sx={{ '&.MuiTypography-root': { color: 'rgba(0, 0, 0, 0.87)' } }}
      />
    </ReferenceField>
  );
};

export default ListActivity;
