import React, { useRef, useState } from 'react';
import CustomList from '../../Components/List/CustomList';
import { FunctionField, TextField, useNotify, useRecordContext } from 'react-admin';
import HeaderList from '../../Components/HeaderList/HeaderList';
import { Backdrop, Box, Button, Card, IconButton, Popover, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { copyText } from '../../utils/helpers';
import CopyAllIcon from '@mui/icons-material/CopyAll';


const List = () => {
  return (
    <CustomList
      rowClick={'edit'}
      paramsList={{
        perPage: 50,
        sort: { field: 'date_created', order: 'ASC' },
        actions: <HeaderList hasSearch hasCreate />,
      }}
    >
      <TextField label={'Nombre'} source={'name'} />
      <TextField label={'Tipo'} source={'type'} />
      <FunctionField
        source='price'
        label='Precio'
        render={(record) => (
          <Box display={'flex'} gap={'3px'} alignItems={'baseline'}>
            <Typography>${record.price}</Typography>
            <Typography fontSize={'12px'} color={'gray'}>{record.currency.toLowerCase()}</Typography>
          </Box>
        )}
      />
      <OptionsMenu source={'options'} label={''} />
    </CustomList>
  );
};

const OptionsMenu = ({ source, label, ...rest }) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const btRef = useRef(null);
  const notify = useNotify();


  const handleModal = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const closeModal = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const handleClickCopy = (e) => {
    e.stopPropagation();
    console.log('record', record);
    copyText(`https://www.pagar.simplefi.tech/${record.merchant_id}/products/${record.id}`);
    notify('Link copiado al portapapeles', { type: 'success' });
  };

  return (
    <Box sx={{ position: 'relative' }} ref={btRef}>
      <IconButton onClick={handleModal}>
        <MoreVertIcon sx={{ fontSize: '18px' }} />
      </IconButton>
      <Backdrop
        open={open}
        onClick={handleModal}
        sx={{ backgroundColor: 'transparent' }}
      />
      <Popover
        open={open}
        anchorEl={btRef.current}
        onClose={closeModal}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Card
          sx={{ padding: '5px' }}
          sxTitle={{
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 600,
            color: '#000000',
          }}
          sxHeader={{
            backgroundColor: '#FDFDFD',
            border: '1px solid #E7E7E7',
            padding: '0px',
            width: '100%',
            height: '100%',
            margin: '0px',
          }}
        >
          <Box>
            <Button onClick={handleClickCopy}>
              Copiar link
            </Button>
          </Box>
        </Card>
      </Popover>
    </Box>
  );
};

export default List;
